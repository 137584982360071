import * as React from "react"
import { useState } from 'react'
import { Link, navigate } from "gatsby"

import Layout from "../../components/layout"
import * as styles from './styles.module.scss'
import backArrow from '../../svg/back-arrow.svg'
import ExhibitionPreview from '../../components/exhibitionPreview/exhibitionPreview'
import BackToTop from '../../components/backToTop/backToTop'

const Artist = ({ pageContext }) => {
  const {artistName, subtitle, description, work, cv, exhibition, press} = pageContext.content
	const [isModalVisible, setIsModalVisible] = useState(false)
	const handleModalOpen = () => {
    setIsModalVisible(true)
  }

  return (
    <Layout>
      <div className={`${styles.root}`}>
				<button className={styles.button} onClick={() => navigate(-1)}>
					<img src={backArrow} />
					<div className={styles.buttonLabel} >Artists</div>
				</button>

        <div className={styles.wrapper}>
					<div className={styles.artistNameWrapper}>
						<h1 className={styles.artistName}>{artistName}</h1>
						<div className={styles.subTitle}>{subtitle}</div>
					</div>
					<div className={styles.headingLinks}>
						<a href="#work">Work</a>
						<a href={cv} target="_blank">CV</a>
						<a href="#exhibitions">Exhibitions</a>
						<a href="#press">Press</a>
					</div>
				</div>

				<div className={styles.wrapper}>
				  <div className={styles.description} dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark.html }}></div>
				</div>

				<div id="work" className={styles.workContainer}>
				  {work.map((tile, index) => {
						return (
							<div key={index} className={styles.work} onClick={handleModalOpen}>
								<ExhibitionPreview
									content={tile}
									type='3-up-skip'
									hasModal='true'
									workType='artistWork'
								/>
							</div>
						)
					})}
				</div>

				<div id="exhibitions" className={styles.exhibitions}>
					<div className={styles.exhibitionsTitle}>Exhibitions</div>
					<div className={styles.exhibitionsDetails}>
						{exhibition.map((each, index) => {
							return (
								<Link key={index} to={each.link}>
									<div className={styles.exhibitionWrapper}>
									  <div className={styles.exhibitionTitle}>{each.title}</div>
									  <div className={styles.exhibitionDate}>{each.date}</div>
									</div>
								</Link>
							)
						})}
					</div>
				</div>

				<div id="press" className={styles.press}>
					<div className={styles.pressTitle}>Press</div>
					<div className={styles.pressDetails}>
						{press.map((each, index) => {
							return (
								<Link key={index} to={each.link}>
									<div className={styles.title}>{each.title}</div>
								</Link>
							)
						})}
					</div>
				</div>
      </div>

			<BackToTop />
    </Layout>
  )
}

export default Artist

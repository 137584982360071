import React from "react"

import * as styles from './styles.module.scss'
import upArrow from '../../svg/up-arrow.svg'

const BackToTop = () => {
	const handleBackToTop = () => {
		window.scrollTo({top: 0, behavior: 'smooth'});
	}

  return (
    <div className={styles.root}>
			<img data-type='icon' className={styles.arrow} src={upArrow}/>
      <button
        className={styles.button}
        onClick={handleBackToTop}
      >
				Back To Top
			</button>
    </div>
  )
}

export default BackToTop

// extracted by mini-css-extract-plugin
export var artistName = "styles-module--artistName--d35c7";
export var artistNameWrapper = "styles-module--artistNameWrapper--04590";
export var button = "styles-module--button--0bcdb";
export var buttonLabel = "styles-module--buttonLabel--64fe3";
export var description = "styles-module--description--16120";
export var exhibitionDate = "styles-module--exhibitionDate--c77e3";
export var exhibitionTitle = "styles-module--exhibitionTitle--b7ff0";
export var exhibitionWrapper = "styles-module--exhibitionWrapper--f758f";
export var exhibitions = "styles-module--exhibitions--a020b";
export var exhibitionsDetails = "styles-module--exhibitionsDetails--dbfec";
export var exhibitionsTitle = "styles-module--exhibitionsTitle--be793";
export var headingLinks = "styles-module--headingLinks--a7da8";
export var press = "styles-module--press--bf0ec";
export var pressDetails = "styles-module--pressDetails--43561";
export var pressTitle = "styles-module--pressTitle--f6a09";
export var root = "styles-module--root--a2951";
export var subTitle = "styles-module--subTitle--2c851";
export var title = "styles-module--title--2b43d";
export var work = "styles-module--work--236de";
export var workContainer = "styles-module--workContainer--80b16";
export var workDimension = "styles-module--workDimension--feb9b";
export var workMaterial = "styles-module--workMaterial--ce0d9";
export var workTitle = "styles-module--workTitle--7aca8";
export var wrapper = "styles-module--wrapper--4590c";